import { useState } from "react";
import { useAuth } from "auth/use-auth";
import { useQueryClient } from "@tanstack/react-query";
import { usePutArtistAttended } from "api/query/put/use-put-artist-attended";
import { usePutArtistFavorite } from "api/query/put/use-put-artist-favorite";
import { usePutArtistWatchlist } from "api/query/put/use-put-artist-watchlist";
import { usePutFestivalEditionArtistAttended } from "api/query/put/use-put-festival-edition-artist-attended";

import Stack from "@mui/material/Stack";

import {
  ActionButton,
  ActionButtonVariant,
} from "../components/action-button/action-button";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const FestivalEditionArtistUserActions = ({
  artist,
  festival_edition_slug,
  user_slug,
}) => {
  const { authToken, noAuthDialog, user } = useAuth();
  const queryClient = useQueryClient();
  const { mutate: artistAttended } = usePutArtistAttended();
  const { mutate: artistFavorite } = usePutArtistFavorite();
  const { mutate: artistWatchlist } = usePutArtistWatchlist();
  const { mutate: festivalEditionAttended } =
    usePutFestivalEditionArtistAttended();

  const user_attended_artist = artist.user_attended_artist;
  const user_attended_artist_festival_edition =
    artist.user_attended_festival_edition_artist;
  const user_artist_favorite = artist.user_artist_favorite;
  const user_watchlist_artist = artist.user_watchlist_artist;

  const handleRefectQueries = () => {
    if (festival_edition_slug) {
      queryClient.refetchQueries({
        queryKey: ["festival_edition", festival_edition_slug, user?.uid],
      });
    }

    if (user_slug) {
      queryClient.refetchQueries({
        queryKey: ["user", user_slug, user?.uid],
      });
    }
  };

  const handleToggleArtistAttended = () => {
    if (!authToken) {
      return noAuthDialog.setShowNoAuthDialog(true);
    }

    artistAttended(
      { attended: !user_attended_artist, authToken, slug: artist.slug },
      {
        onSettled: () => {
          handleRefectQueries();
        },
      },
    );
  };

  const handleToggleArtistFavorite = () => {
    if (!authToken) {
      return noAuthDialog.setShowNoAuthDialog(true);
    }

    artistFavorite(
      { add: !user_artist_favorite, authToken, slug: artist.slug },
      {
        onSettled: () => {
          handleRefectQueries();
        },
      },
    );
  };

  const handleToggleArtistWatchlist = () => {
    if (!authToken) {
      return noAuthDialog.setShowNoAuthDialog(true);
    }

    artistWatchlist(
      { add: !user_watchlist_artist, authToken, slug: artist.slug },
      {
        onSettled: () => {
          handleRefectQueries();
        },
      },
    );
  };

  const handleToggleFestivalEditionArtistAttended = () => {
    if (!authToken) {
      return noAuthDialog.setShowNoAuthDialog(true);
    }

    festivalEditionAttended(
      {
        artist: artist.slug,
        attended: !user_attended_artist_festival_edition,
        authToken,
        festivalEdition: festival_edition_slug,
      },
      {
        onSettled: () => {
          handleRefectQueries();
        },
      },
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      {/*<ActionButton
        on={user_attended_artist_festival_edition}
        onClick={handleToggleFestivalEditionArtistAttended}
        variant={ActionButtonVariant.FestivalEditionArtist}
      />*/}

      <ActionButton
        on={user_attended_artist}
        onClick={handleToggleArtistAttended}
        variant={ActionButtonVariant.Artist}
      />

      {/*<ActionButton
        on={user_watchlist_artist}
        onClick={handleToggleArtistWatchlist}
        variant={ActionButtonVariant.Watchlist}
      />*/}

      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList>
          <MenuItem onClick={handleToggleArtistFavorite}>
            <ListItemIcon sx={{ color: user_artist_favorite ? "red" : "" }}>
              <FavoriteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {user_artist_favorite ? "Favorited" : "Favorite"}
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={handleToggleArtistWatchlist}>
            <ListItemIcon sx={{ color: user_watchlist_artist ? "black" : "" }}>
              {user_watchlist_artist ? (
                <CheckCircleIcon fontSize="small" />
              ) : (
                <WatchLaterIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>
              {user_watchlist_artist
                ? "Added to Watchlist"
                : "Add to Watchlist"}
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Stack>
  );
};
