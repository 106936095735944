export const FestivalEditionPercentageSeen = ({ festival_edition }) => {
  const numberOfActs = festival_edition.artists.length;

  const numberOfActsSeen = festival_edition.artists.filter(
    (artist) => artist.user_attended_artist,
  ).length;

  return (
    <>
      You've seen {((numberOfActsSeen / numberOfActs) * 100).toFixed(2)}% (
      {numberOfActsSeen}/{numberOfActs}) of this lineup!
    </>
  );
};
