import { useAuth } from "auth/use-auth";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import dayjs from "dayjs";

import { queryFestival } from "api/query/query-festival";

import { Helmet } from "react-helmet";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { FestivalHeader } from "./components/festival-header/festival-header";
import { FestivalEditionLinks } from "views/festival-edition/components/select-previous-edition/components/festival-edition-links/festival-edition-links";
import { FestivalsRow } from "views/components/festivals-row/festivals-row";
import { FestivalTimesAttended } from "views/festival/components/festival-times-attended/festival-times-attended";

export const loader =
  ({ queryClient, user }) =>
  async ({ params }) => {
    const query = queryFestival({ slug: params.slug, user });

    return queryClient.ensureQueryData(query);
  };

export const Festival = () => {
  const { user } = useAuth();
  const params = useParams();
  const { data } = useQuery(queryFestival({ slug: params.slug, user }));

  const today = dayjs();

  const upcomingFestivals = data?.editions
    .filter((edition) => today.isBefore(edition.end_date))
    .sort((a, b) => dayjs(a.start_date) - dayjs(b.start_date));
  const pastFestivals = data?.editions.filter((edition) =>
    today.isAfter(edition.end_date),
  );

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const metaTitle = `${data.name} | mfests.com - music festivals`;
  const metaDescription = `${data.name} music festival artists lineup and information`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
      </Helmet>

      <Box mb={3}>
        <FestivalHeader festival={data} />
        
        <Box textAlign="center"><FestivalTimesAttended festival={data} /></Box>

        {mobile && data.editions.length > 1 &&  (
          <Box mt={2}>
            <FestivalEditionLinks
              festivalName={data.name}
              festivalEditions={data.editions}
            />
          </Box>
        )}
      </Box>

      <FestivalsRow center festivals={upcomingFestivals} showDates />

      <Box p={5}>
        <Divider />
      </Box>

      <FestivalsRow festivals={pastFestivals} showActions />
    </>
  );
};
