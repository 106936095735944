import dayjs from "dayjs";

export const FestivalTimesAttended = ({ festival }) => {
  const today = dayjs();

  const pastFestivals = festival?.editions.filter((edition) =>
    today.isAfter(edition.end_date),
  );

  const numberOfAttendedFestivals = pastFestivals.filter(
    (x) => x.user_attended_festival_edition,
  ).length;

  if (numberOfAttendedFestivals > 0) {
    return (
      <>
        You've attended {festival.name} {numberOfAttendedFestivals} time
        {numberOfAttendedFestivals > 1 && "s"}!
      </>
    );
  }

  return null;
};
