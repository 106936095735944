import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { ArtistList } from "views/festival-edition/components/artist-list/artist-list";
import { ListOptions } from "views/components/grid-or-list/grid-or-list";

import { LayoutContext } from "views/layout/layout";
import { UserContext } from "views/user/user";

export const UserArtists = ({
  artists,
  seen_artists,
  favorite_artists,
  watchlist_artists,
}) => {
  const layoutContext = useContext(LayoutContext);
  const userContext = useContext(UserContext);

  const [artistsToDisplay, setArtistsToDisplay] = useState(artists);
  const [artistsFilter, setArtistsFilter] = useState("all");
  const [fadeSeenArtists, setFadeSeenArtists] = useState(false);

  useEffect(() => {
    switch (artistsFilter) {
      case "all":
        setArtistsToDisplay(artists);
        break;
      case "favorite":
        setArtistsToDisplay(favorite_artists);
        break;
      case "seen":
        setArtistsToDisplay(seen_artists);
        break;
      case "watchlist":
        setArtistsToDisplay(watchlist_artists);
        break;
    }
  }, [
    artistsFilter,
    artists,
    seen_artists,
    favorite_artists,
    watchlist_artists,
  ]);

  if (!artists || artists.length === 0) {
    return <>No Artists Added!</>;
  }

  return (
    <>
      <Box mb={3} textAlign="right">
        <ButtonGroup size="small">
          <Button
            onClick={() => setArtistsFilter("all")}
            variant={artistsFilter === "all" ? "contained" : "outlined"}
          >
            All
          </Button>
          <Button
            onClick={() => setArtistsFilter("seen")}
            variant={artistsFilter === "seen" ? "contained" : "outlined"}
          >
            {seen_artists.length > 0 && seen_artists.length} Seen
          </Button>
          <Button
            onClick={() => setArtistsFilter("favorite")}
            variant={artistsFilter === "favorite" ? "contained" : "outlined"}
          >
            {favorite_artists.length > 0 && favorite_artists.length} Favorites
          </Button>
          <Button
            onClick={() => setArtistsFilter("watchlist")}
            variant={artistsFilter === "watchlist" ? "contained" : "outlined"}
          >
            {watchlist_artists.length > 0 && watchlist_artists.length} Watchlist
          </Button>
        </ButtonGroup>

        {!userContext.isAuthUser && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fadeSeenArtists}
                  onChange={() => setFadeSeenArtists(!fadeSeenArtists)}
                />
              }
              label="Fade Out Artists I've Seen"
              size="small"
            />
          </Box>
        )}
      </Box>

      {artistsToDisplay.length > 0 ? (
        <ArtistList
          artists={artistsToDisplay}
          grid_or_list={ListOptions.List}
          per_row={layoutContext.isMobile ? 3 : 4}
          user_slug={userContext.username || userContext.email_sha256}
        />
      ) : (
        <></>
      )}
    </>
  );
};
