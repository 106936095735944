import { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import { ArtistRow } from "views/festival-edition/components/artist-row/artist-row";
import {
  GridOrList,
  ListOptions,
} from "views/components/grid-or-list/grid-or-list";

import { paginate } from "utils/pagination/paginate";

const SortOptions = {
  Alphabetically: "Alphabetically",
  // Country: "Country",
  // Day: "Day",
  Popularity: "Popularity",
};

export const ArtistList = ({
  artists,
  festival_edition_slug,
  festival_name,
  grid_or_list,
  per_row = 3,
  user_slug,
}) => {
  let artistsData = artists || [];

  // for now, let's not show act names and remove duplicate artists
  /*artistsData = artistsData.filter(
    (obj, index, self) => index === self.findIndex((o) => obj.slug === o.slug),
  );*/

  const artistListRef = useRef(null);

  const [sort, setSort] = useState(SortOptions.Popularity);
  const [list, setList] = useState(grid_or_list ?? ListOptions.List);
  const [sortedArtists, setSortedArtists] = useState(artistsData);
  const [showFestivalCount, setShowFestivalCount] = useState(false);

  const getPageSize = () => {
    return list === ListOptions.List ? 10 : per_row === 4 ? 8 : 9;
  };

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(getPageSize());

  const pageCount = Math.ceil(artistsData.length / pageSize);

  const handlePaginationChange = (event, value) => {
    setPage(value);

    window.scrollTo(0, artistListRef.current.offsetTop);
  };

  const alphabetically = [...artistsData].sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const popularity = [...artistsData].sort(
    (a, b) =>
      Number(b.services?.spotify?.popularity || 0) -
      Number(a.services?.spotify?.popularity || 0),
  );

  const countryAndPopularity = [...artistsData].sort((a, b) => {
    const aCountry = a.services?.musicbrainz?.area?.name || "z";
    const bCountry = b.services?.musicbrainz?.area?.name || "z";

    const aPopularity = Number(a.services?.spotify?.popularity || 0);
    const bPopularity = Number(b.services?.spotify?.popularity || 0);

    if (aCountry > bCountry) {
      return 1;
    } else if (aCountry < bCountry) {
      return -1;
    }

    if (aPopularity < bPopularity) {
      return 1;
    } else if (aPopularity > bPopularity) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    }

    return 0;
  });

  const dayAndPopularity = [...artistsData].sort((a, b) => {
    const aPopularity = Number(a.services?.spotify?.popularity || 0);
    const bPopularity = Number(b.services?.spotify?.popularity || 0);

    if (a.date > b.date) {
      return 1;
    } else if (a.date < b.date) {
      return -1;
    }

    if (aPopularity < bPopularity) {
      return 1;
    } else if (aPopularity > bPopularity) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    }

    return 0;
  });

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  useEffect(() => {
    switch (sort) {
      case SortOptions.Alphabetically:
        setSortedArtists(alphabetically);
        break;
      case SortOptions.Country:
        setSortedArtists(countryAndPopularity);
        break;
      case SortOptions.Day:
        setSortedArtists(dayAndPopularity);
        break;
      case SortOptions.Popularity:
        setSortedArtists(popularity);
        break;
    }

    setPage(1);
  }, [artists, sort]);

  useEffect(() => {
    setPageSize(getPageSize());
  }, [list]);

  return (
    <>
      <Box mb={2} ref={artistListRef}>
        <Grid container alignItems="center">
          <Grid item xs>
            <GridOrList list={list} setList={setList} />

            <FormControlLabel
              control={
                <Checkbox
                  checked={showFestivalCount}
                  onChange={() => setShowFestivalCount(!showFestivalCount)}
                />
              }
              label="Show Festival Count"
              size="small"
            />
          </Grid>

          <Grid item xs>
            <FormControl fullWidth variant="standard">
              <InputLabel id="sort">Sort</InputLabel>
              <Select
                labelId="sort"
                value={sort}
                onChange={handleChange}
                label="Sort"
              >
                {Object.values(SortOptions).map((sortOption, index) => (
                  <MenuItem key={index} value={sortOption}>
                    {sortOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={list === ListOptions.Grid ? 5 : 0}>
        {paginate({
          array: sortedArtists,
          page_size: pageSize,
          page_number: page,
        }).map((artist, i) => (
          <Grid
            item
            xs={list === ListOptions.Grid ? 12 / per_row : 12}
            key={`${artist.id}}${i}`}
          >
            <ArtistRow
              artist={artist}
              festival_edition_slug={festival_edition_slug}
              festival_name={festival_name}
              key={artist.slug}
              show_festival_count={showFestivalCount}
              user_slug={user_slug}
              variant={list}
            />
          </Grid>
        ))}
      </Grid>

      {pageCount > 1 && (
        <Stack alignItems="center">
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePaginationChange}
          />
        </Stack>
      )}
    </>
  );
};
